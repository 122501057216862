import { Canvas } from "@react-three/fiber";
import { Model } from "./Model";
import { PresentationControls, Environment } from "@react-three/drei";
import { EffectComposer, Bloom } from "@react-three/postprocessing";
import { useState, useEffect } from "react";

export default function ThreeD() {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Pas de drempelwaarde aan indien nodig
  const [config, setConfig] = useState({ mass: 2, tension: 300 });

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Pas de drempelwaarde aan indien nodig
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (isMobile) {
      setConfig({ mass: 5, tension: 600 }); // Verhoog de waarden voor mobiele apparaten
    } else {
      setConfig({ mass: 2, tension: 300 }); // Standaardwaarden voor desktop
    }
  }, [isMobile]);
  useEffect(() => {
    if (isMobile) {
      setConfig({ mass: 2, tension: 900 }); // Verhoog de waarden voor mobiele apparaten
    } else {
      setConfig({ mass: 2, tension: 300 }); // Standaardwaarden voor desktop
    }
  }, [isMobile]);

  return (
    <Canvas style={{ width: "100%", height: "40vh" }}>
      <Environment preset="forest" />
      <PresentationControls
        global
        config={config}
        snap={{ mass: 4, tension: 900 }}
        rotation={[0, 0, 0]}
        polar={[-Math.PI / 1.6, Math.PI / 1.6]}
        azimuth={[-Math.PI / 1.4, Math.PI / 2]}
      >
        <Model />
        <EffectComposer>
          <Bloom
            luminanceThreshold={1}
            intensity={6.85}
            levels={6}
            mipmapBlur
          />
        </EffectComposer>
      </PresentationControls>
    </Canvas>
  );
}
