import React, { useState, useEffect } from "react";

function About() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = currentTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
  });

  return (
    <div className="flex flex-col lg:flex-row justify-between mb-10 gap-8 lg:gap-0">
      <div className="lg:w-1/2 font-medium">
        <p className="lg:text-lg mb-4 lg:pr-8">
          Hey, I’m Zayd! As a student developer, I'm passionate about blending
          design with development to create seamless and intuitive web
          experiences. I find excitement in crafting clean, well-structured code
          using technologies like JavaScript and React, and I’m drawn to the
          challenge of turning creative ideas into reality.
        </p>
        <p className="lg:text-lg mb-4 lg:pr-8">
          My journey as a developer is fueled by a love for problem-solving and
          an eagerness to continuously learn and grow. Whether it's building
          dynamic applications or exploring new frameworks, I'm always striving
          to improve. I believe that combining good design with robust
          development is key to making the web a more engaging space. When I’m
          not coding, I’m often playing sports or diving into the latest tech
          trends to stay ahead in this ever-evolving field.
        </p>
      </div>
      <div className="w-full lg:w-1/2 flex flex-row lg:flex-col justify-between h-full  lg:items-center gap-10 lg:gap-14">
        <div className="flex flex-col gap-10 lg:gap-14">
          <div className="text-sm w-full">
            <p>Based in Belgium Boom</p>
            <p>
              Current time {formattedTime} [<span className="ball">●</span>{" "}
              {formattedDate}]
            </p>
          </div>
          <div className="w-full max-w-md text-left w-full">
            <ul className="list list-square list-inside text-xl font-bold mb-2">
              <li>Experience</li>
            </ul>
            <ul className="list">
              <li>[01] Programming assistant - 2 years</li>
              <li>[02] Internship - Feb 2025</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
