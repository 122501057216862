import Gallery from "./components/Gallery";
import About from "./components/About";
import Talen from "./components/Talen";
import ThreeD from "./components/ThreeD";

function Home() {
  return (
    <div className="max-w-screen-2xl mx-auto p-4">
      <About />
      <ThreeD />
      <Gallery />
      <Talen />
    </div>
  );
}

export default Home;
