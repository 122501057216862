import React, { useState, useEffect } from "react";
import "../Footer.css";

function Footer() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  const formattedDate = currentTime.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "long",
  });

  const nameLetters = "HIT ME UP!".split(""); // Split per letter, inclusief spaties

  return (
    <footer className="bg-dark px-4 py-4 sm:py-8 text-white md:mt-20 ">
      <div className="max-w-screen-2xl mx-auto">
        <a href="mailto:zayd.maadal@hotmail.com">
          <h3 className="h1 menu__item footerH2">
            <div className="menu__letters">
              {nameLetters.map((letter, idx) => (
                <span className="h2" key={idx} style={{ "--index": idx }}>
                  {letter === " " ? "\u00A0" : letter}{" "}
                  {/* Als het een spatie is, render &nbsp; */}
                </span>
              ))}
            </div>
            <div className="menu__letters clone">
              {nameLetters.map((letter, idx) => (
                <span className="h2" key={idx} style={{ "--index": idx }}>
                  {letter === " " ? "\u00A0" : letter}{" "}
                  {/* Als het een spatie is, render &nbsp; */}
                </span>
              ))}
            </div>
          </h3>
        </a>
        <ul className="flex flex-col sm:flex-row items-center justify-between w-full mt-12 sm:mt-24 lg:mt-32 text-sm sm:text-xs gap-8">
          <li>
            <a href="https://www.instagram.com/zayd.maadal/">[Zayd]</a>
          </li>
          <li>
            <a
              href="/documents/Zayd_Maadal_CV.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resume
            </a>
          </li>

          <li>
            <a href="mailto:zayd.maadal@hotmail.com">Email</a>
          </li>
          <li>
            <a href="https://www.linkedin.com/in/zayd-maadal-90168b204/">
              LinkedIn
            </a>
          </li>
          <li>
            Belgium Boom - {formattedTime} [<span className="ball">●</span>{" "}
            {formattedDate}]
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
